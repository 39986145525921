/* Inspired by https://www.w3schools.com/howto/howto_css_timeline.asp */
<template>
  <section id="timeline">
    <div v-for="(post, index) in posts" :key="post.title" class="mb-5">
      <div
        class="timeline-post"
        :class="{
          'post-left': index % 2 === 0,
          'post-right': index % 2 !== 0,
        }"
      >
        <TimelinePost
          :title="post.title"
          :text="post.text"
          :company="post.company"
          :year="post.year"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import TimelinePost from "./TimelinePost"
  import posts from "@/resources/components/timeline.json"

  export default {
    name: "Timeline",
    components: {
      TimelinePost,
    },
    computed: {
      posts: function() {
        return posts
      },
    },
  }
</script>
<style scoped lang="scss">
  #timeline {
    position: relative;
  }

  #timeline::after {
    left: 50%;
    position: absolute;
    width: 4px;
    top: 0;
    bottom: 0;
    background: $subtle;
    content: "";
    z-index: 1;
  }

  .timeline-post {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }

  .post-left {
    padding-right: 40px;
  }

  .post-right {
    left: 50%;
    padding-left: 40px;
  }

  /* Timeline dots  */
  .timeline-post::after {
    position: absolute;
    content: "";
    right: -17px;
    background-color: $background;
    border: 4px solid $accent;
    top: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 2;
  }

  .post-right::after {
    left: -16px;
  }

  @media screen and (max-width: 600px) {
    #timeline::after {
      left: 31px;
    }

    .timeline-post {
      width: 100%;
      padding-left: 70px;
    }

    .timeline-post::after {
      left: 16px;
    }

    .post-left,
    .post-right {
      left: 0;
    }
  }
</style>
