<template>
  <div>
    <div class="row d-flex justify-content-between mb-5">
      <div class="col-12 col-md-7 col-lg-8 d-flex flex-column">
        <h2 class="mb-5">
          {{ content.title }}
        </h2>
        <p class="mb-5">
          {{ content.subTitle }}
        </p>

        <div class="mb-5 text-center text-md-start">
          <CtaLink :title="content.ctaTitle" :to="{ name: 'About' }" />
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-3 d-flex justify-content-center">
        <img
          class="img-fluid rounded-circle align-self-center"
          :src="content.avatarUrl"
          alt="Alexander Euler profile picture"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import content from "@/resources/views/home/content.json"

  import CtaLink from "@/components/buttons/CtaLink"

  export default {
    components: {
      CtaLink,
    },
    computed: {
      content: function() {
        return content
      },
    },
  }
</script>

<style lang="scss" scoped></style>
