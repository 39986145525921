<template>
  <h4>
    {{ title }}
  </h4>
  <div class="metadata">
    <span v-if="company" class="company">{{ company }}</span>
    <div class="timespan">
      <span class="from">{{ year.from }}</span>
      <span v-if="year.to" class="to">{{ year.to }}</span>
    </div>
  </div>
  <div v-if="text" class="timeline-post">
    {{ text }}
  </div>
</template>

<script>
  export default {
    name: "TimelinePost",
    props: {
      text: {
        type: String,
        default: "",
      },
      title: {
        type: String,
        default: "",
      },
      year: {
        type: Object,
        default: () => ({}),
      },
      company: {
        type: String,
        default: "",
      },
    },
  }
</script>
<style scoped>
  .timeline-post {
    font-size: 0.9em;
    margin-top: 10px;
  }

  .metadata {
    opacity: 0.5;
    font-size: 0.9em;
  }

  .to::before {
    content: " - ";
  }
</style>
