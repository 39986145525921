<template>
  <div class="d-flex justify-content-center">
    <span class="me-3 mb-3">Contact me</span>
    <a
      href="#"
      data-name="info"
      data-domain="alexander-euler"
      data-tld="de"
      onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
    >
      info@alexander-euler.de
    </a>
  </div>
  <div class="d-flex justify-content-center">
    <span class="me-3 mb-3">Find me</span>

    <div v-if="content.link_github" class="link-icon mx-2">
      <a :href="content.link_github">
        <img class="img-fluid" src="./icons/github.svg" />
      </a>
    </div>

    <div v-if="content.link_linkedin" class="link-icon mx-2">
      <a :href="content.link_linkedin">
        <img class="img-fluid" src="./icons/linkedin.png" />
      </a>
    </div>

    <div v-if="content.link_xing" class="link-icon mx-2">
      <a :href="content.link_xing">
        <img class="img-fluid" src="./icons/xing.png" />
      </a>
    </div>
  </div>
</template>

<script>
  import content from "@/resources/components/layout/footer/find_me.json"

  export default {
    computed: {
      content: function() {
        return content
      },
    },
  }
</script>

<style scoped>
  .link-icon {
    height: 27px;
    width: 27px;
  }
</style>
