<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title mb-2">{{ title }}</h5>
      <h6 class="card-subtitle text-muted">{{ subTitle }}</h6>

      <div v-if="badges.length" class="mb-3">
        <span
          v-for="(badge, index) in badges"
          :key="index"
          class="badge bg-secondary"
        >
          {{ badge }}
        </span>
      </div>

      <p class="card-text">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {
        default: "",
        type: String,
      },
      subTitle: {
        default: "",
        type: String,
      },
      title: {
        default: "",
        type: String,
      },
      badges: {
        default: () => [],
        type: Array,
      },
    },
    data: function() {
      return {
        colorClasses: ["primary", "secondary", "success", "danger", "dark"],
      }
    },
    methods: {
      getRandomColorClass: function() {
        return this.colorClasses[
          `bg-${Math.floor(Math.random() * this.colorClasses.length)}`
        ]
      },
    },
  }
</script>

<style scoped lang="scss">
  .card-body {
    padding: 1.5rem;
  }

  .text-muted {
    min-height: 2.5em;
    color: $subtle !important;
  }

  .badge {
    margin-right: 0.3rem;
  }
</style>
